import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { colors } from 'fitify-ui/src/theme/colors'
import { ESubscriptionVariant } from 'fitify-ui-onboarding/src/types'
import { BREAKPOINTS } from 'fitify-ui-onboarding/src/utils/styled'

import { Stack } from '../Stack'
import { Text } from '../Typography'

export interface PlanListItemStyledProps {
  isActive?: boolean
}

export interface StyledPlanListItemIndicatorStyledProps {
  isActive?: boolean
}

export interface StyledPlanListPriceStyledProps {
  variant?: 'discount'
  isActive?: boolean
}

export type StyledHeaderProps = {
  textAlign?: 'center' | 'left'
  hasCoach?: boolean
}

export interface StyledPriceWrapperProps {
  direction?: 'column' | 'row'
}

export const StyledSubscriptionHeader = styled(Stack)<StyledHeaderProps>`
  width: 100%;
  margin-bottom: 6px;

  ${(props) => {
    if (!props.hasCoach) {
      const textAlign = props.textAlign || 'left'

      return css`
        text-align: ${textAlign};

        @media ${BREAKPOINTS.MD.lte} {
          text-align: center;
        }
      `
    }

    return null
  }}

  b {
    font-weight: 500;
  }

  @media ${BREAKPOINTS.MD.lte} {
    padding: 0 1rem 0;
  }
`

export const StyledPaywallHeading = styled(Text)`
  @media ${BREAKPOINTS.MD.lte} {
    font-size: 2rem;
  }
`

export const StyledPaywallSubheading = styled(Text)`
  margin: 1.125rem 0;
  color: var(--c-text);
`

export const StyledChipContainer = styled.div<{
  align?: 'left' | 'right' | 'center'
}>`
  display: flex;

  @media ${BREAKPOINTS.MD.lte} {
    justify-content: ${(props) => {
      switch (props.align) {
        case 'left':
          return 'flex-start'
        case 'right':
          return 'flex-end'
        default:
          return 'center'
      }
    }};
  }
`

export const StyledSubscriptionHeaderCoachImage = styled.div`
  flex-shrink: 0;
  width: 96px;
  height: 96px;
  margin-right: 16px;
`

export const StyledPlanList = styled.div<{ variant?: ESubscriptionVariant }>`
  display: flex;
  width: 100%;
  border-radius: 12px;
  gap: 8px;

  ${(props) => {
    switch (true) {
      case props.theme.hcV1:
        if (props.variant === ESubscriptionVariant.PLAIN) {
          return css`
            flex-direction: row;
            width: 100%;
          `
        } else {
          return css`
            flex-direction: column;
            width: 100%;
          `
        }
      default:
        return null
    }
  }}
`

export const StyledPlanListItem = styled.div<PlanListItemStyledProps>`
  position: relative;

  width: 100%;
  min-height: 64px;

  display: flex;
  align-items: center;

  border: 1px solid transparent;
  border-radius: 12px;
  padding: 0.5rem;
  cursor: pointer;

  ${(props) => {
    switch (true) {
      case props.theme.digitalV2 || props.theme.digitalV4:
        return css`
          min-height: 62px;

          font-family: inherit;
          justify-content: center;
          text-align: center;

          border-width: 2px;
          border-color: ${props.isActive
            ? 'var(--c-gray200)'
            : 'var(--c-blue-border)'};
          color: ${props.isActive ? 'var(--c-white)' : 'var(--c-blue75)'};
          transition: border-color 0.3s;
        `
      default:
        return css`
          background: var(--c-white);
          border-color: ${props.isActive
            ? 'var(--c-blue400)'
            : 'var(--c-gray200)'};
          justify-content: left;

          font-family: 'Graphik LCG Web';
          text-align: ${props.theme.hcV1 ? 'left' : 'center'};

          &:hover,
          &:focus {
            border: 1px solid var(--c-blue400);
          }
        `
    }
  }}
`

export const StyledPlanListItemIndicator = styled.div<StyledPlanListItemIndicatorStyledProps>`
  ${(props) => {
    switch (true) {
      case props.theme.hcV1:
        return css`
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;

          width: 24px;
          height: 24px;
          border-radius: 24px;

          border: 2px solid;
          background: ${props.isActive ? 'var(--c-blue400)' : 'transparent'};
          border-color: ${props.isActive
            ? 'var(--c-blue400)'
            : 'var(--c-gray400)'};

          svg path {
            fill: var(--c-white);
          }
        `
      default:
        return css`
          display: none;
        `
    }
  }}
`

export const StyledPlanListItemWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;

  ${StyledPlanListItemIndicator} {
    margin-right: 0.5rem;
  }
`

export const StyledPlanListItemContentBlock = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.theme.hcV1 ? 'space-between' : 'center'};
  align-items: center;
`

export const StyledMonthlyPriceInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  font-size: 11px;
`

export const SavePercentBadge = styled.div`
  padding: 0.3em 0.6em;

  background: ${colors.Blue400};
  border-radius: 6px;
  font-weight: 400;
  color: ${colors.White};
  font-size: 13px;
  margin-top: 4px;
`

export const StyledPlanListItemPriceInfo = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  ${(props) => {
    switch (true) {
      case props.theme.digitalV2:
      case props.theme.digitalV3:
        return css`
          position: initial;
        `
      default:
        return null
    }
  }}
`

export const StyledPlanListItemName = styled.div<{ isActive?: boolean }>`
  margin-bottom: 5px;
  font-weight: 500;

  ${(props) => {
    switch (true) {
      case props.theme.hcV1:
        return css`
          font-size: 14px;
          color: var(--c-gray600);
        `
      case props.theme.digitalV4:
        return css`
          font-size: 14px;
          color: ${props.isActive ? 'var(--c-text)' : 'var(--c-blue75)'};
        `
      default:
        return css`
          font-size: 14px;
          margin-top: 5px;
          color: var(--c-text);
        `
    }
  }}
`

export const StyledPlanListMonthlyPrice = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  font-size: 12px;
  text-align: right;
`

export const StyledPriceWrapper = styled.div<StyledPriceWrapperProps>`
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  gap: ${(props) => (props.theme.digitalV4 ? '4px' : '8px')};
`

export const StyledPlanListPrice = styled.span<StyledPlanListPriceStyledProps>`
  font-weight: 500;
  ${(props) => {
    switch (true) {
      case props.theme.hcV1:
        if (props.variant === 'discount') {
          return css`
            font-size: 20px;
            color: var(--c-blue400);
            text-decoration-line: line-through;

            @media ${BREAKPOINTS.MD.lte} {
              font-size: 16px;
            }
          `
        } else {
          return css`
            font-size: 20px;
            color: var(--c-black);

            @media ${BREAKPOINTS.MD.lte} {
              font-size: 16px;
            }
          `
        }

      case props.theme.digitalV4: {
        if (!props.isActive && props.variant !== 'discount') {
          return css`
            font-size: 16px;
            color: var(--c-blue75);
          `
        }
        if (props.variant === 'discount') {
          return css`
            color: #ff4778;
            text-decoration: line-through;
            font-weight: 400;
            font-size: 13px;
          `
        }
        return
      }

      default:
        if (props.variant === 'discount') {
          return css`
            color: var(--c-new-pink);
            font-size: 13px;
            text-decoration: line-through;
          `
        } else {
          return css`
            font-size: 16px;
            color: var(--c-text);
          `
        }
    }
  }}
`

export const StyledPlanListItemContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  ${StyledPlanListItemContentBlock}:first-of-type {
    width: 100%;
  }
`
